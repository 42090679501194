<template>
  <div
    class="page-container w-screen h-vh-9/10 max-vh-9/10 overflow-y-hidden relative flex flex-wrap justify-center"
  >
    <div class="container flex justify-center flex-wrap">
      <div
        class="flex flex-wrap w-screen justify-around items-center max-h-9/10-screen overflow-y-hidden"
      >
        <div class="flex flex-wrap w-full justify-center">
          <h1 class="w-full text-center my-5 text-primary text-3xl">
            ¡Gracias por formar parte de Sabar! <br>
          </h1>
          <button class="bg-secondary border-2 border-secondary-light text-white rounded-xl px-5 py-3"
            @click="sendEmailConfirm()"
          >
            Click aquí para confirmar tu registro
          </button>
          <div
            v-if="confirmError == false"
            class="text-red-400"
          >
            Ha habido un error al confirmar tu registro, por favor comunicate con nuestro equipo de soporte
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "PromotionsPage",
  data: function() {
    return {
      id: "",
      confirmError: true
    };
  },
  computed: {},
  mounted() {
    this.$store.commit("updateBgApp", "");
  },
  destroyed() {},
  methods: {
    ...mapMutations(["setIsUserSideBarOpen"]),
    sendEmailConfirm() {
      let self = this;
      self.$store.dispatch("sendEmailConfirm", self.$route.params.id).then(() => {
        setTimeout(() => {
          self.setIsUserSideBarOpen({status: true, timeout: 0})
        }, 200);

        self.$swal({
          icon: "success",
          title: 'Solicitud procesada',
          text: '¡Muchas gracias! Ahora puedes iniciar sesión con tus credenciales de registro',
          showConfirmButton:false,
          timer: 10000,
        });
        
        self.$router.push({ name:'our-services', params: {} })
      }).catch((e) => {
        // console.log(e);
        self.confirmError = true;
        throw e
      })
    }
  }
};
</script>
<style ></style>
